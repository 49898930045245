.tabs[data-v-7889e463] {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(229, 229, 229);
  padding-bottom: 3px;
}
.tabs img[data-v-7889e463] {
  width: auto;
  height: 60px;
}
.tab_content[data-v-7889e463] {
  width: 130px;
  text-align: center;
  cursor: pointer;
}
.tab3[data-v-7889e463] {
  width: 225px;
}
.tab_title[data-v-7889e463] {
  font-size: 13px;
}
.tab_flex[data-v-7889e463] {
  display: flex;
  align-items: center;
}
.triangle-container[data-v-7889e463] {
  margin: 0 30px;
}
.triangle-container img[data-v-7889e463] {
  width: 26px;
  height: auto;
}
.switch_icon[data-v-7889e463] {
  position: absolute;
  right: 2px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  z-index: 1;
  display: flex;
  align-items: center;
}
.switch_icon span[data-v-7889e463] {
  font-size: 12px;
  margin-right: 2px;
}
.top_select[data-v-7889e463] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.el-select[data-v-7889e463] {
  margin: 0px;
}
.item_icon[data-v-7889e463] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-7889e463] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.item_icon p[data-v-7889e463] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.bottom_button[data-v-7889e463] {
  margin-top: 16px;
  float: left;
}
[data-v-7889e463] textarea {
  min-height: 145px !important;
}
.top[data-v-7889e463] {
  position: relative;
}
.el-message__content a[data-v-7889e463] {
  text-decoration: underline;
  color: #409eff;
}
[data-v-7889e463] .el-dropdown-menu__item {
  display: block;
}
.top--10[data-v-7889e463] {
  top: -10px;
}
.bottom-10[data-v-7889e463] {
  bottom: 10px;
}
.rotate--90[data-v-7889e463] {
  transform: rotate(-90deg);
}
.import-company-btn[data-v-7889e463] {
  width: 100px;
  display: inline-block;
  text-align: center;
}
.table-cell-sort-render[data-v-7889e463] {
  color: orange;
  cursor: pointer;
}
.company-name-box[data-v-7889e463] {
  display: flex;
  align-items: center;
}
.company-name-box .item_icon[data-v-7889e463] {
  width: 95%;
}
.company-name-box .icon-fuzhi1[data-v-7889e463] {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.mobileRec-icon[data-v-7889e463] {
  color: #67c23a;
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
  margin-left: 4px;
}
.mobile-no-auth[data-v-7889e463] {
  display: inline-block;
  margin-left: 4px;
}
.mobile-no-auth-icon[data-v-7889e463] {
  color: #afacac;
  cursor: pointer;
  font-weight: 600;
}
.has-auth-icon[data-v-7889e463] {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
  font-weight: 600;
}
.setUpStatusCj-icon[data-v-7889e463] {
  color: red;
  margin-left: 4px;
}
.opera-btn-box[data-v-7889e463] {
  text-align: right;
  margin-top: 10px;
}
.xgmStyle[data-v-7889e463] {
  cursor: pointer;
}
.sqtbcj-icon[data-v-7889e463] {
  color: red;
  margin-left: 4px;
}
.jz-btn[data-v-7889e463] {
  border: 0;
  padding: 5px 4px;
}
.sq-content-box[data-v-7889e463] {
  display: inline-block;
  margin-left: 4px;
}
.table-instance[data-v-7889e463] {
  width: 100%;
}
.m0-p5[data-v-7889e463] {
  margin-left: 0px;
}
.m-l0-r5[data-v-7889e463] {
  margin-left: 0px;
  margin-right: 5px;
}