.common_dialog_box .common_title[data-v-5d6b4a5e] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
.common_dialog_box .common_title span[data-v-5d6b4a5e] {
  color: red;
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box .el-select[data-v-5d6b4a5e] {
  margin: 0 !important;
}
.record[data-v-5d6b4a5e]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.help[data-v-5d6b4a5e] {
  color: #17a2b8;
  font-size: 13px;
  position: absolute;
  right: -44px;
  cursor: pointer;
}
.help i[data-v-5d6b4a5e] {
  color: #17a2b8;
  font-size: 13px;
  margin-left: 3px;
}